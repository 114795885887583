.team-member-item:hover img{
    transform: scale3d(1.1, 1.1, 1);
}
.team-member-item img{
    transition: opacity .6s, transform .6s, -webkit-transform .6s;
}
.team-social{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.team-social:hover{
    background-color: #0080b1;
}
.team-social:hover svg{
    color: #fff;
}