.customer-slider{
    overflow: hidden;
    padding: 0 40px;
}
.customer-slider .slick-arrow{
    width: 30px;
    height: 30px;
}
.customer-slider .slick-arrow::before {
    display: none;
}
.customer-slider .slick-arrow svg{
    width: 30px;
    height: 30px;
    color: #2493e2;
}
.customer-slider .slick-arrow.slick-prev{
    left: 0;
}
.customer-slider .slick-arrow.slick-next{
    right: 0;
}