.expiredLink {
    font-size: 15px;
    background: #ff000099;
    padding: 15px;
    color: #fff !important;
}
.sales_details_form{
    padding: 40px;
    margin: 20px 0;
    background: #F2F2F2;
    border-radius: 30px;
    border: 1px solid #E9E9E9;
}
.sales_details_form .form-heading{
    font-size: 2rem;
    border-bottom: 2px solid #8f9499;
    margin-bottom: 20px;
}
.sales_details_form .form-control {
    width: 100%;
    border: 1px solid #ACD4EE;
    border-radius: 30px;
    color: #000;
    font-size: 16px;
    padding: 10px 15px;
    position: relative;
}
.sales_details_form .sales_details_submit {
    width: max-content;
    padding: 12px 50px;
    border-radius: 30px;
    font-size: 20px;
    margin: 40px auto 0;
}
#dateBirth::before { 
    content: attr(data-placeholder);
    width: 64%;
    color: #a598ab;
    position: absolute;
    background: #fff;
}
#dateBirth:focus::before,
#dateBirth.hasvalue::before{
    display: none;
}
