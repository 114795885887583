.consumer-dashboard .form-driver-now label {
    font-weight: 400;
}

#pickup_address {
    padding-left: 30px;
}

.btn-consumer-profile {
    background-color: #062951 !important;
    border: 1px solid #062951 !important;
    border-radius: 0 !important;
    min-width: 110px;
}

.btn-consumer-profile:hover {
    background-color: #000 !important;
}

.trip-select{
    border: 1px solid #3ea5b8;
    border-radius: 50px;
    padding: 5px;
}