.faq_accordation .accordion-header .accordion-button {
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    border-radius: 0 !important;
    gap: 10px;
    box-shadow: unset !important;
}
.faq_accordation .accordion-header .accordion-button.collapsed{
    background: #2493e2;
}
.faq_accordation .accordion-header .accordion-button:hover{
    background: #000;
}
.faq_accordation .accordion-item {
    margin-bottom: 10px;
    border-radius: 0 !important;
}
.faq_accordation .accordion-collapse{
    border-radius: 0 !important;
}
.faq_accordation .accordion-header .accordion-button::after{
    display: none;
}
.minus-accordation-icon, .plus-accordation-icon{
    font-size: 15px;
}
.faq_accordation .accordion-header .accordion-button .minus-accordation-icon{
    display: block;
}
.faq_accordation .accordion-header .accordion-button .plus-accordation-icon{
    display: none;
}
.faq_accordation .accordion-header .accordion-button.collapsed .minus-accordation-icon{
    display: none;
}
.faq_accordation .accordion-header .accordion-button.collapsed .plus-accordation-icon{
    display: block;
}