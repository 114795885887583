.header-main{
    background-image: radial-gradient(at top center, #fff 19%, #e1eef8 66%);
}
.btn-primary-blue{
    background-color: #2493e2 !important;
    color: #fff !important; 
}
.btn-primary-blue:hover{
    background-color: #fff !important;
    color: #2493e2 !important; 
    outline: 2px solid #2493e2;
    outline-offset: -2px;
}
.nav-link{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
}
.nav-link:hover{
    color: #2493e2 !important;
}
.dropdown-header .dropdown-toggle {
    color: #000;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-right: 12px;
    font-size: 16px;
    height: fit-content;
    line-height: 1;
}
.dropdown-header:last-child .dropdown-toggle{
    margin-right: 0;
    padding-right: 0 !important;
}
.btn-primary-red .dropdown-toggle{
    background-color: #FF0000 !important;
    color: #fff !important; 
    border-radius: 1.5rem;
}
.btn-primary-red .dropdown-toggle:hover{
    background-color: #fff !important;
    color: #FF0000 !important; 
    outline: 2px solid #FF0000;
    outline-offset: -2px;
}
.dropdown-header .dropdown-menu{
    background-color: #cce9ff;
    padding: 0 !important;
    right: 0;
    left: auto !important;
    width: 240px;
    border-radius: 0;
    border: 0;
}
.dropdown-header .dropdown-menu .dropdown-item:hover{
    background-color: transparent !important;
    color: #2493e2;
}
/* mobile menu */
.navbar-toggler{
    border: none !important;
    color: #000 !important;
}
.navbar-toggler.collapsed .close-icon {
    display: none;
}
.navbar-toggler .close-icon {
    display: block;
    border: 2px solid #000;
    width: 20px;
}
.navbar-toggler.collapsed .bars-icon {
    display: block;
}
.navbar-toggler .bars-icon {
    display: none;
}
.navbar-toggler:focus{
    box-shadow: unset !important;
}
@media (max-width: 1199px){
    .navbar-collapse {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        background: #cce9ff;
        text-align: left;
    }
    .nav-link, .dropdown-header{
        padding: 23px 79px !important;
        background: transparent !important;
        color: #000 !important;
    }
    .dropdown-header .dropdown-toggle{
        background: transparent !important;
        color: #000 !important;
        padding: 0 !important;
    }
    .nav-link:hover, .dropdown-header .dropdown-toggle:hover{
        background: transparent !important;
        color: #000 !important;
        outline: none !important;
    }
}
@media (max-width: 767px){
    .nav-link, .dropdown-header{
        padding: 12px 40px !important;
    }
    .header-logo{
        padding-left: 20px;
    }
}
