/* Delivered orders table */
.delivered-orders-table thead th:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.delivered-orders-table thead th {
    background-color: #062752;
    color: #fff;
    font-family: Asap;
    font-size: 14px;
    padding: 10px .5rem;
    border: 0.5px solid #fff;
    text-align: center;
    vertical-align: middle;
}
.delivered-orders-table thead th:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.delivered-orders-table tbody tr td:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.delivered-orders-table tbody tr td:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.delivered-orders-table tbody tr td {
    border: 1px solid #fff;
    vertical-align: middle;
    background: #9a9997;
    color: #fff;
    font-size: 14px;
    padding: 0.1rem .5rem;
}
.delivered-orders-table tbody tr td p{
    text-transform: capitalize;
}
.delivered-orders-table{
    border-collapse: separate;
    border-spacing: 0 5px;
}

.delivered_order_question {
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    background: #062752 !important;
    border-radius: 11px;
    padding: 4px;
    font-size: 11px !important;
    position: relative;
    width: 100%;
    border: 2px solid #fff !important;
    outline: 2px solid #3ea5b8 !important;
    cursor: pointer;
}
.delivered-orders-table .orders-button-messages td {
    background: #3fa9f5;
}
.delivered-orders-table .orders-button-messages .orders_messages_div {
    background: #c9e3ff;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    color: #000;
}

@media (max-width: 991px){
    .delivered-orders-table{
        width: 200% !important;
    }
}