.form .form-control{
    border-color: #2493e2;
    border-radius: 30px;
    font-size: 20px;
    line-height: 1.3;
    height: auto;
    padding: 12px 20px 12px 30px;
    box-shadow: none !important;
}
.form .form-control::placeholder{
    color: #9b9ba1;
}

.form .form-control:focus {
    border-color: #007AFF;
}
.form .btn-submit{
    width: auto;
    height: auto;
    padding: 8px 60px;
    border-radius: 30px;
    background: #2493E2 !important;
    font-size: 23px;
    border: 1px solid transparent !important;
    transition: all 0.3s;
}
.form .btn-submit:hover {
    transform: translateY(-5px);
}
.form-check-input{
    width: 20px !important;
    height: 20px !important;
    border-color: #495057 !important;
    margin-top: 0 !important;
}
.form .form-check{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
}
.filter-color{
    filter: brightness(200%) contrast(100%) saturate(143%) blur(0px) hue-rotate(193deg);
}