.sales-form .form-control {
    border: 1px solid #dceefb;
    border-radius: 30px;
    background: #fff;
    padding: 15px 12px 15px 50px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
}
.sales-form .form-control:focus {
    border-color: #007AFF;
    box-shadow: none;
}
.sales-form .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    color: #98989E;
}
.sales-form .btn-submit{
    width: auto;
    height: auto;
    padding: 12px 40px;
    min-width: 30%;
    border-radius: 30px;
    background: #007AFF !important;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid transparent !important;
    transition: all 0.3s;
}
.sales-form .btn-submit:hover {
    transform: translateY(-5px);
}