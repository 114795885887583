.rent-free-form .form-control {
    border-radius: 15px;
    padding: 9px 20px;
    font-size: 17px;
}
.rent-free-form .btn-submit {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    margin-top: 0;
}
.rent-free-form .form-check {
    align-items: flex-start;
}