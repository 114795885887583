.markeing-ads-section .text-control{
    border: 1px solid #addaf8;
    border-radius: 50px;
    padding: 10px;
    width: 150px;
    padding: 5px 10px;
}
.report_send_sms, .submit_message , .send_msg_asap, .process_order{
    background: #062752 !important;
    color: #fff !important;
    border-radius: 50px !important;
    position: relative;
    padding: 5px 15px !important;
    border: 2px solid #fff !important;
    outline: 2px solid #3ea5b8;
}
.report_send_sms:focus, .submit_message:focus, .send_msg_asap:focus, .process_order:focus{
    outline: 2px solid #3ea5b8 !important;
}
.report_send_sms:hover, .submit_message:hover, .send_msg_asap:hover, .process_order:hover{
    background: #000 !important;
}
.select_asap {
    background: #fff !important;
    color: #000 !important;
    padding: 5px 15px;
    width: 100px;
    border-radius: 50px !important;
    border: 1px solid #addaf8 !important;
}
.select_asap.active{
    background: #008000 !important;
    color: #fff !important;
}
.send_text_data .form-control{
    border: 1px solid #addaf8;
}
#sms_link {
    border-radius: 50px;
}
.image_list_btn {
    background: #062752 !important;
    color: #fff !important;
    border-radius: 50px !important;
    position: relative;
    padding: 2px 15px !important;
    border: 2px solid #fff !important;
    outline: 2px solid #3ea5b8;
    font-size: 14px !important;
}
.image_list_btn:focus{
    outline: 2px solid #3ea5b8 !important;
}
.image_list_btn:hover{
    background: #000 !important;
}
.ads_section ul li.active img {
    border: 5px solid #ffff00;
}
.del_msg {
    background: transparent !important;
    color: #000 !important;
    border: none !important;
    padding: 0 !important;
}