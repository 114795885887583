.yt-lite .lty-playbtn {
    opacity: 1;
    background-color: #00b3ff;
    border-radius: 0;
    width: 97px;
    height: 67px;
}
.yt-lite:hover .lty-playbtn {
    opacity: 0.8;
    background-color: #00b3ff;
}
.yt-lite.lyt-activated::before, .yt-lite.lyt-activated > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
}
.popup-modal .modal-dialog {
    max-width: 720px;
}
.video-popup{
    width: 100%;
    height: 420px;
    object-fit: cover;
}