.chat-block {
    border-radius: 50px;
    background: #c9e4ff;
    max-height: 480px;
    height: 100%;
    width: 100%;
    padding: 10px 20px;
    position: relative;
}
.chat-block .welcome-msg-btn{
    background-color: #052852;
    border-radius: 50px;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: Asap;
}
.chat-block .message {
    margin-top: 10px;
}
.chat-block .content p{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}
.chat-block .inner_msg_box {
    width: 100%;
    height: 215px;
    overflow: auto;
    margin-top: 15px;
}
.chat-block .chat_msg_send {
    background-color: #f1f1f1;
    border: 1px solid #2db7e6;
    border-radius: 50px;
    padding: 10px 50px 10px 20px;
    box-shadow: none;
    width: 100%;
}
.chat-block .send-msg-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 25px;
    cursor: pointer;
}
.chat-block .inner_msg_box .message_box {
    padding: 9px;
}
.chat-block .inner_msg_box .message_box p {
    margin-bottom: 0;
    font-weight: 400;
}
.chat-block .inner_msg_box .message_box p.message-adminname {
    font-weight: 600;
}
.chat-block .inner_msg_box .message_box.admin_msg:first-child{
    background-color: yellow;
    border-radius: 15px;
}
@media (max-width: 767px){
    .chat-block{
        border-radius: 20px;
    }
    .chat-block .inner_msg_box {
        height: 180px;
    }
}