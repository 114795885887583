/* Sales Dahsboard */
.sales-nav-left {
    position: sticky;
    min-height: 85vh;
    top: 100px;
    flex-wrap: nowrap !important;
}
.sales-nav-left .nav-link {
    color: #fff !important;
    font-family: 'Lato';
    padding: 8px 8px 8px 30px !important;
}
.sales-nav-left .nav-link.active {
    background-color: rgba(255,255,255,.16);
}
.sales-nav-left .nav-link svg {
    margin-right: 10px;
}
.sales-nav-left .nav-link:hover, .sales-nav-left .nav-link:focus{
    background-color: rgba(255,255,255,.16) !important;
    color: #fff !important;
}
.sales-nav-left .nav-dropdown .nav-link{
    padding-left: 50px !important;
}
.sales-nav-left .logout_menu{
    margin-top: auto;
}
.sales-nav-left .logout_menu .nav-link{
    color: red !important;
    font-weight: 700;
}
.sales-tab-content {
    background: #fff;
    padding-left: 8%;
    padding-right: 8%;
}
iframe.documentation-iframe {
    border: 2px inset #9a9a9a;
}
.title_font {
    font-size: 28px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 0;
}
@media (max-width: 991px){
    .sales-nav-left .nav-link {
        color: #007bff !important;
    }
    .sales-nav-left{
        min-height: auto;
    }
    .sales-nav-left .nav-link:hover, .sales-nav-left .nav-link:focus{
        color: #0056b3 !important;
    }
    .sales-nav-left .logout_menu {
        margin-top: 0;
    }
}
@media (max-width: 480px){
    .sales-tab-content {
        padding-left: 0;
        padding-right: 0;
    }
}

/* My Profile tab */
.my_profile_tabs .tab-content {
    background-color: #fff;
}

/* Add Sales Report */
.report_filter_input {
    width: 100% !important;
    border: 1px solid #9FCFF3 !important;
    border-radius: 15px !important;
    line-height: 2 !important;
}
.report_table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    margin: 30px 0;
}
.report_table tr:first-child th:first-child {
    border-top-left-radius: 15px;
}
.report_table tr th:first-child, .report_table tr td:first-child {
    border-left: 1px solid #ccc;
}
.report_table tr:first-child th:last-child {
    border-top-right-radius: 15px;
}
.report_table tr th {
    border-top: 1px solid #ccc;
    background: #0071bd;
    color: #fff;
    font-weight: 400;
    padding: 8px;
    vertical-align: middle;
}
.report_table tr th, .report_table tr td {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    line-height: 1.5;
    text-transform: capitalize;
}
.report_table tr:last-child td:first-child {
    border-bottom-left-radius: 15px;
}
.report_table tr.grey td {
    background: #e6e6e6;
}
.report_table tr th:first-child, .report_table tr td:first-child {
    border-left: 1px solid #ccc;
}
.report_table tr td {
    padding: 8px;
}
.report_table tr:last-child td:last-child {
    border-bottom-right-radius: 15px;
}
@media (max-width: 620px) {
    .report_table thead {
        display: none;
    }
    .report_table tr {
        display: block;
        margin-bottom: 15px;
    }
    .report_table tr td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }
    .report_table tr td {
        text-align: right !important;
        display: block;
        border: 1px solid #ccc;
        border-radius: 0 !important;
    }
}
/* Delivery Report */
.reports_filter_section {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.reports_filter_left {
    flex: 1;
}
.reports_filter_right img {
    width: 30px;
    object-fit: contain;
    cursor: pointer;
}
.reports_filter_right {
    display: flex;
    align-items: center;
    flex: 0 0 20%;
    justify-content: end;
    gap: 5px;
    padding-top: 32px;
}
@media (max-width: 767px) {
    .reports_filter_section {
        flex-direction: column;
    }
    .reports_filter_left {
        width: 100%;
    }
}
.deliveries_block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}
.deliveries_block span:last-child {
    border-right: 0;
}

.deliveries_block span {
    flex: 1;
    display: block;
    border-right: 1px solid #ccc;
}
.report_table tr.tfoot td {
    background: #333;
    color: #fff;
    border: none;
}
.deliveries_block span .deliveries_block_title {
    display: none;
}
@media (max-width: 620px) {
    .deliveries_block span .deliveries_block_title {
        display: block;
        text-align: left;
        font-weight: 600;
    }
    .deliveries_block {
        flex-direction: column;
        width: 100%;
    }
    .deliveries_block span {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border: none;
    }
    span.hide-mobile {
        display: none;
    }
}