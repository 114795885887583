.div-box-shadow{
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .11);
    border: 1px solid #f0f0f0;
}
.border-div-blue{
    border: 1px solid #2493e2;
}
.career-border-div{
    border: 1px solid #e8e8e8;
}
.career-border-div:hover{
    box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, .09);
}