.order-invoice-head.modal-header {
    border-bottom: 0;
}
.print_invoice_btn {
    background-color: #f1f1f1 !important;
    color: #000 !important;
    border: 1px solid #000 !important;
    min-width: 150px;
}
.print_invoice_btn:hover {
    background-color: #000 !important;
    color: #fff !important;
}
@media (max-width: 1200px){
    .popup-order-modal .modal-dialog {
        max-width: calc(100% - var(--bs-modal-margin) * 2);
    }
}

@media (max-width: 767px){
    .table-flex strong {
        position: relative !important;
    }
}

@media print {
    html, body {
      padding: 10px !important;
    }
  }