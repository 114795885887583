@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Lato";
  src: url('./assests/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: "Roboto";
  src: url('./assests/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: "Alata";
  src: url('./assests/fonts/Alata-Regular.woff');
}

@font-face {
  font-family: "Asap";
  src: url('./assests/fonts/Asap-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, pre {
  font-family: 'Lato', sans-serif !important;
}
:focus-visible {
  outline: none;
}
.font-roboto{
  font-family: 'Roboto', sans-serif;
}

.font-asap{
  font-family: 'Asap', sans-serif;
}

.font-alata{
  font-family: 'Alata', sans-serif;
}

.hover\:text-primary-blue:hover {
  --tw-text-opacity: 1;
  color: rgb(36 147 226 / var(--tw-text-opacity)) !important;
}
.sales-pages{
  font-family: 'Alata', sans-serif;
}
.message{
  font-weight: 700;
}
.filter-color-purple{
  filter: brightness(78%) contrast(100%) saturate(141%) blur(0px) hue-rotate(204deg);
}
.filter-color-green{
  filter: brightness(119%) contrast(100%) saturate(141%) blur(0px) hue-rotate(72deg);
}
.filter-color-blue{
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(191deg);
}
/* Bounce animation */
.bounceInDown {
  animation-name: bounceInDown;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.bounceInDown.delay-60{
  animation-delay: .6s;
}
.bounceInDown.delay-45{
  animation-delay: .4s;
}
.bounceInDown.delay-100{
  animation-delay: .10s;
}
.bounceInDown.delay-70{
  animation-delay: .7s;
}
.bounceInDown.delay-50{
  animation-delay: .5s;
}
.bounceInDown.delay-30{
  animation-delay: .3s;
}
.bounceInDown.delay-15{
  animation-delay: .15s;
}
.bounceInDown.delay-20{
  animation-delay: .2s;
}
@keyframes bounceInDown{
  60%, 75%, 90%, 0%, 100% {
    animation-timing-function: cubic-bezier(.215,.61,.355,1);
  }
  0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0);
  }
  60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0);
  }
  75% {
      transform: translate3d(0, -10px, 0);
  }
  90% {
      transform: translate3d(0, 5px, 0);
  }
  100% {
      transform: none;
  }
}

/* Slide down animation */
.slideInDown {
  animation-name: slideInDown;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes slideInDown{
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
      transform: translate3d(0, 0, 0);
  }
}